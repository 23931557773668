import Background from './background'
import Post from './post'
import React from 'react'
import Wrapper from './wrapper'
import { css } from '@emotion/core'
import { minWidth } from '../breakpoints'

export default ({ posts }) => {
  const margin = 30

  return (
    <Background id="blog">
      <Wrapper
        styles={css`
          margin-top: 5rem;
        `}
      >
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            [minWidth.medium]: {
              marginRight: -margin,
            },
          }}
        >
          {posts.items.map(({ item }) => (
            <Post key={item.wordpress_id} item={item} margin={margin} />
          ))}
        </div>
      </Wrapper>
    </Background>
  )
}
