import * as Color from 'color'

import Button from '../components/button'
import Content from '../components/content'
import Img from 'gatsby-image'
import Quote from '../components/quote'
import React from 'react'
import Supertitle from '../components/supertitle'
import Wrapper from '../components/wrapper'
import { colors } from '../variables'
import { css } from '@emotion/core'
import font from '../fonts'
import { minWidth } from '../breakpoints'

const glow = `0 10px 50px ${Color(colors.blue60)
  .fade(0.75)
  .string()}`

export default ({ content }) => (
  <Wrapper
    styles={css`
      margin-top: 5rem;
      margin-bottom: 5rem;
    `}
  >
    {content.map((block, index) => {
      const even = index % 2 === 0

      return (
        <div
          css={css`
            &:not(:last-child) {
              margin-bottom: 3.75rem;
            }
          `}
          key={index}
        >
          <div
            css={css`
              margin-top: ${even ? '3.5em' : '2.5em'};

              ${minWidth.tablet} {
                margin-top: ${even ? '4.5em' : '2.25em'};
              }

              ${minWidth.laptop} {
                margin-top: ${even ? '5.5em' : '2.25em'};
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  order: ${even ? '0' : '1'};

                  ${minWidth.laptop} {
                    align-items: center;
                    flex-direction: row;
                    justify-content: space-between;
                  }
                `}
              >
                <div
                  css={css`
                    box-shadow: ${glow};
                    flex-shrink: 0;
                    width: 75%;

                    ${minWidth.laptop} {
                      width: 60%;
                    }
                  `}
                >
                  <Img
                    fluid={block.imageLarge.localFile.childImageSharp.fluid}
                  />
                </div>
                {block.quote && (
                  <div
                    css={css`
                      order: ${even ? '-1' : '0'};
                      margin-bottom: ${even ? '2.5rem' : '0'};
                      margin-top: ${even ? '0' : '3rem'};

                      ${minWidth.laptop} {
                        order: 0;
                        margin-bottom: ${even ? '7.5%' : '0'};
                        margin-top: ${even ? '0' : '7.5%'};
                        width: 35%;
                      }
                    `}
                  >
                    <h2
                      css={css`
                        font-family: ${font('Raleway')};
                        font-weight: 800;
                        margin-bottom: 0.75em;
                      `}
                    >
                      <Quote>{block.quote}</Quote>
                    </h2>
                    <Supertitle
                      styles={css`
                        margin: 0;

                        ${minWidth.tablet} {
                          margin: 0;
                        }
                      `}
                    >
                      Josh & Hope
                    </Supertitle>
                  </div>
                )}
              </div>
              <div
                css={css`
                  align-self: flex-end;
                  box-shadow: ${glow};
                  margin-bottom: ${even ? '0' : '-10%'};
                  margin-top: ${even ? '-10%' : '0'};
                  width: 55%;

                  ${minWidth.tablet} {
                    width: 45%;
                  }

                  ${minWidth.laptop} {
                    margin-bottom: ${even ? '0' : '-5%'};
                    margin-top: ${even ? '-5%' : '0'};
                    margin-right: 15%;
                    width: 35%;
                  }
                `}
              >
                <Img fluid={block.imageSmall.localFile.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </div>
      )
    })}
  </Wrapper>
)
