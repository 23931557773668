import * as Color from 'color'

import { StaticQuery, graphql } from 'gatsby'

import Anchor from './anchor'
import Button from './button'
import GatsbyImage from 'gatsby-image'
import Quote from './quote'
import React from 'react'
import Supertitle from './supertitle'
import Wrapper from './wrapper'
import { colors } from '../variables'
import { minWidth } from '../breakpoints'

export default class HomeHeader extends React.Component {
  state = {
    menuOpen: false,
  }

  render() {
    const { image, quote, subtitle } = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            map: file(relativePath: { eq: "map.png" }) {
              childImageSharp {
                fixed(width: 1600, quality: 80) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        `}
        render={data => (
          <div
            css={{
              backgroundColor: '#FAFCFF',
              backgroundImage: `url(${data.map.childImageSharp.fixed.src})`,
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '125% auto',
              paddingBottom: 0,
              paddingTop: 60,
              marginBottom: 60,
              [minWidth.tablet]: {
                backgroundPosition: '50%',
                backgroundSize: 'cover',
                paddingBottom: 60,
                marginBottom: 0,
              },
              [minWidth.laptop]: {
                paddingTop: 80,
                paddingBottom: 80,
              },
            }}
          >
            <Wrapper>
              <div
                css={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  [minWidth.tablet]: {
                    flexDirection: 'row',
                    justifyContent: 'center',
                  },
                }}
              >
                <div
                  css={{
                    boxShadow: `0 10px 50px ${Color(colors.blue60).fade(0.75)}`,
                    borderRadius: '100%',
                    height: 180,
                    marginBottom: 30,
                    overflow: 'hidden',
                    width: 180,
                    willChange: 'transform',
                    [minWidth.tablet]: {
                      flexShrink: 0,
                      height: 'auto',
                      marginBottom: 0,
                      marginRight: 40,
                      maxWidth: 400,
                      width: '40%',
                    },
                    [minWidth.laptop]: {
                      marginRight: '10%',
                    },
                  }}
                >
                  <GatsbyImage fluid={image.localFile.childImageSharp.fluid} />
                </div>
                <div
                  css={{
                    maxWidth: 440,
                    [minWidth.tablet]: {
                      maxWidth: 540,
                    },
                  }}
                >
                  <h1
                    css={{
                      marginBottom: 15,
                      [minWidth.tablet]: {
                        marginBottom: 25,
                      },
                      '@media(min-width: 1200px)': {
                        fontSize: '3.5em',
                      },
                    }}
                  >
                    <Quote>{quote}</Quote>
                  </h1>
                  <Supertitle
                    position="bottom"
                    styles={{
                      marginBottom: 15,
                      [minWidth.tablet]: {
                        marginBottom: 40,
                      },
                      [minWidth.laptop]: {
                        fontSize: '1.33em',
                        marginBottom: 60,
                      },
                    }}
                  >
                    {subtitle}
                  </Supertitle>
                  <Anchor target="#blog">
                    <Button
                      styles={{
                        marginRight: 'auto',
                        transform: 'translateY(50%)',
                        [minWidth.tablet]: {
                          transform: 'none',
                        },
                      }}
                      color="orange"
                      direction="down"
                    >
                      Lees ons blog
                    </Button>
                  </Anchor>
                </div>
              </div>
            </Wrapper>
          </div>
        )}
      />
    )
  }
}
