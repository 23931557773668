import React from 'react'
import { colors } from '../variables'

export default ({ children }) => (
  <div css={{ color: colors.blue30 }}>
    <Sign>&ldquo;</Sign>
    {children}
    <Sign>&rdquo;</Sign>
  </div>
)

const Sign = ({ children }) => (
  <span
    css={{
      color: colors.blue60,
    }}
  >
    {children}
  </span>
)
