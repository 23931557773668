import Button from './button'
import Img from 'gatsby-image'
import React from 'react'
import Supertitle from './supertitle'
import Wrapper from './wrapper'
import { css } from '@emotion/core'
import { minWidth } from '../breakpoints'

export default ({data}) => {
  if (!data.show) {
    return null;
  }

  if (data.button.title === '') {
    data.button.title = 'Lees meer'
  }

  return (
    <div css={css`position: relative;`}>
      <div
        css={css`
          align-items: center;
          display: flex;
          height: 100%;
          overflow: hidden;
          position: absolute;
          width: 100%;
          z-index: -1;
        `}
      >
        <div
          css={css`
            background: rgba(0, 0, 0, 0.4);
            height: 100%;
            position: absolute;
            width: 100%;

            ${minWidth.medium} {
              background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4));
            }
          `}
        ></div>

        <Img
          css={css`
            height: 100%;
            width: 100%;
            z-index: -1;
          `}
          alt={data.title}
          fluid={data.background.file.image.fluid}
        />
      </div>

      <Wrapper
        styles={css`
          padding-bottom: 3em;
          padding-top: 3em;
          display: flex;

          ${minWidth.tablet} {
            padding-bottom: 6em;
            padding-top: 6em;
          }
        `}
      >
        <div
          css={css`
            margin-left: auto;

            ${minWidth.medium} {
              width: 50%;
            }
          `}
        >
          {(data.supertitle !== '') && (
            <Supertitle
              css={css`
                color: white;
                text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
              `}
            >
              {data.supertitle}
            </Supertitle>
          )}

          <h2
            css={css`
              color: white;
              text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
            `}
          >
          {data.title}
          </h2>

          <p
            css={css`
              color: white;
              text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
            `}
          >
          {data.text}
          </p>

          <Button
            color="orange"
            to="https://voja.travel/"
          >
            {data.button.title}
          </Button>
        </div>
      </Wrapper>
    </div>
  )
}
