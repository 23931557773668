import Author from '../components/author'
import Blog from '../components/blog'
import CallToAction from '../components/call-to-action'
import Footer from '../components/footer'
import Header from '../components/header'
import HomeContent from '../components/home-content'
import HomeHeader from '../components/home-header'
import Layout from '../components/layout'
import React from 'react'
import SEO from '../components/seo'
import { graphql } from 'gatsby'

export default class Index extends React.Component {
  state = {
    noScroll: false,
  }

  render() {
    const {
      data: { data },
    } = this.props

    return (
      <Layout>
        <SEO
          title="Home"
          description={data.seoDescription}
          noScroll={this.state.noScroll}
        />
        <Header onMenuChange={noScroll => this.setState({ noScroll })} />
        <HomeHeader
          image={data.headerImage}
          quote={data.headerQuote}
          subtitle={data.headerSubtitle}
        />
        <HomeContent content={data.content} />
        <Author />
        <Blog posts={this.props.data.posts} />
        <CallToAction data={data.cta} />
        <Footer />
      </Layout>
    )
  }
}

export const query = graphql`
  query HomeQuery {
    data: wordpressJoshuaHome {
      headerImage {
        localFile {
          childImageSharp {
            fluid(
              maxWidth: 800
              maxHeight: 800
              quality: 80
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      headerQuote
      headerSubtitle
      content {
        quote
        imageLarge {
          localFile {
            childImageSharp {
              fluid(
                cropFocus: CENTER
                maxWidth: 720
                maxHeight: 480
                quality: 80
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        imageSmall {
          localFile {
            childImageSharp {
              fluid(
                cropFocus: CENTER
                maxWidth: 420
                maxHeight: 280
                quality: 80
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      cta {
        show
        supertitle
        title
        text
        button {
          title
          target
        }
        background {
          file: localFile {
            image: childImageSharp {
              fluid(maxWidth: 1600, maxHeight: 600, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      seoDescription
    }

    posts: allWordpressPost(sort: { fields: date, order: DESC }) {
      items: edges {
        item: node {
          wordpress_id
          link
          date(formatString: "DD MMMM YYYY", locale: "nl")
          fields: wordpress_fields {
            title
            image {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 240, maxWidth: 380, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
